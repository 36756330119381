<template>
    <div class="container">
        <h1>{{$t('app.siteTitle')}}</h1>
        <p>
            {{ $t('typeSelection.subtitle') }}
        </p>
        <loader-overlay
            :loading="saving"
        >
            <div class="row mt-5">
                <div
                    v-for="type in types"
                    :key="type.id"
                    class="col-12 col-md-4 mb-4"
                >
                    <a
                        href="javascript:void(0);"
                        class="d-block border border-light bg-white rounded text-center shadow p-4"
                        role="button"
                        @click.prevent="onSetType(type.id)"
                    >
                        <span class="iconify display-4 mb-3 text-cyrrus-orange" :data-icon="type.icon"></span>
                        <p class="mb-0 h4">{{ type.name }}</p>
                    </a>
                </div>
            </div>
        </loader-overlay>
    </div>
</template>

<script>
import ViewBase from '../ViewBase.vue'
import LoaderOverlay from '../../../../Components/LoaderOverlay.vue'

export default {
    name: 'TypeSelection',
    extends: ViewBase,
    components: {
        LoaderOverlay
    },
    data () {
        return {
            saving: false
        }
    },
    computed: {
        types () {
            return [
                { id: 'FO', name: this.$t('typeSelection.typeNames.person'), icon: 'fa-regular:user' },
                { id: 'PO', name: this.$t('typeSelection.typeNames.company'), icon: 'fa-regular:building' },
                { id: 'OS', name: this.$t('typeSelection.typeNames.selfemployed'), icon: 'fa-solid:briefcase' }
            ]
        }
    },
    methods: {
        async onSetType (typeId) {
            // @todo temporary create session, in another sprint pass there parameters of external acquirer
            if (!this.sessionData) {
                await this.$api.clientSession.create({})
            }
            await this.saveSessionData({
                clientType: typeId
            })
            this.$emit('next')
        }
    }
}
</script>
